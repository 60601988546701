// import React from 'react';
import React, { useState } from "react";
import LanguagePack from '../../components/LanguagePack';
import ReactPlayer from "react-player";
import "../../App.css";
import { Link } from "react-router-dom";
import apple from "../../images/applepay.png";
import google from "../../images/googlepay.png";
import linkedin from "../../images/linkedIn_icon.svg"
import tiktok from "../../images/tikTok_icon.svg"
import twitter from "../../images/twitter_icon.svg"
import instagram from "../../images/instagram_icon.svg"
import youTube from "../../images/youTube_icon.svg"
import donation from "../../images/donation_icon.svg"
import "./HeroSection.css";

function HeroSection(props) {

  const language = (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;

  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);

  const [state, setState] = useState({
    playing: true,
    muted: true,
  });
  const { playing, muted } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleMuteUnMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const [copywrites, setCopyWrites] = useState([
  ]);

  return (
    <main className="mt-5 mb-5">
        <div className="container">
          <div className="hero-container">
            <h1>The Korean Metaverse</h1>
            <div className="bottom-section">
              <div className="hero-buttons">
                <fieldset style={{borderLeft: '2px solid white', borderRadius: '5px', padding: '5px'}}>
                  <legend>Koolpeace <span className="subtitle">(Comunidad de 🇰🇷 Julian Moon 🌙)</span></legend>
                  <>
                    <a href={'https://play.google.com/store/apps/details?id=com.Koolpeace.Koolpeace'} target="_blank">
                      <img
                        className="downloadBtn"
                        src={google}
                        alt="Get It On Google Play"
                      />
                    </a>
                  </>
                  <>
                    <a href={'https://apps.apple.com/app/id1484321611'} target="_blank">
                      <img className="downloadBtn" src={apple} alt="Get It On Apple App Store" />
                    </a>
                  </>
                </fieldset>
                <fieldset style={{borderLeft: '2px solid white', borderRadius: '5px', padding: '5px'}}>
                  <legend>Korean Dating</legend>
                  <>
                    <a href={'https://play.google.com/store/apps/details?id=com.Koolpeace.KoreanDating'} target="_blank">
                      <img
                        className="downloadBtn"
                        src={google}
                        alt="Get It On Google Play"
                      />
                    </a>
                  </>
                  <>
                    <a href={'https://apps.apple.com/app/id1510090447'} target="_blank">
                      <img className="downloadBtn" src={apple} alt="Get It On Apple App Store" />
                    </a>
                  </>
                </fieldset>

                <div className="hero-social-icons">
                  <div className="hs_icon">
                  <a href={ "https://tiktok.com/@JulianMoonLuna" } target="_blank">
                  <img src={tiktok} alt="tiktok-icon" />
                  </a>
                  </div>
                  <div className="hs_icon">
                  <a href={ "https://youtube.com/@JulianMoonLuna" } target="_blank">
                  <img src={youTube} alt="youTube-icon"/>
                  </a>
                  </div>
                  <div className="hs_icon">
                  <a href={ "https://instagram.com/JulianMoonLuna" } target="_blank">
                  <img src={instagram} alt="instagram-icon"/>
                  </a>
                  </div>
                  <div className="hs_icon">
                  <a href={ "https://twitter.com/JulianMoonLuna" } target="_blank">
                  <img src={twitter} alt="twitter-icon"/>
                  </a>
                  </div>
                  <div className="hs_icon">
                  <a href={ "https://linkedin.com/in/JulianMoonLuna" } target="_blank">
                  <img src={linkedin} alt="linkedin-icon"/>
                  </a>
                  </div>
                  <div className="hs_icon">
                  <a href={ "https://buy.stripe.com/5kAdSQ3Fgclq5K84gg" } target="_blank">
                  <img src={donation} alt="linkedin-icon"/>
                  </a>
                  </div>
                </div>
              </div>
              <div className="play-pause-container">
                <div className="play-pause-button" onClick={handlePlayPause}>
                  {!props?.videoPlayStatus && <i className={"fa-solid fa-play"} onClick={() => props?.playVideo()} />}
                  {props?.videoPlayStatus && <i className={"fa-solid fa-pause"} onClick={() => props?.pauseVideo()} />}
                </div>
                <div className="mute-unmute-button" onClick={handleMuteUnMute}>
                  {!props?.videoMutedStatus && <i className={"fa-solid fa-volume-up"} onClick={() => props?.switchMutedVideo()} />}
                  {props?.videoMutedStatus && <i className={"fa-solid fa-volume-mute"} onClick={() => props?.switchMutedVideo()} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
  );
}

export default HeroSection;
